import React, { Component } from 'react';
import Ax from '../../hoc/Ax';
import '../../responsive.css';
import '../AboutUs/AboutUs.css';
import Footer from '../../components/Layout/Footer';

class AboutUs extends Component {

   

    render() {

        return (
            <Ax>
                {/* <section className="m-custom">
                    <header className="header-about">
                        
                    </header>
                </section> */}
                <section>
                    <div className="container-fluid mt-100 spx-2">
                        <div className="row">
                            <div className="col-xs-12 col-sm-10 col-md-10 co-lg-10 co-xl-10 mx-auto">
                                <div className="">
                                    <div className="d-inline-block">
                                        <div className="story-title text-center">About us</div>
                                       
                                <p className="story-subtitle mt-3">
                                        Welcome to Wistran (Translation and localization Services). We are offering reliable solutions to businesses of <b>all sizes and shapes.</b> We have qualified translators, multilingual specialists who bring exceptional and competitively-priced services to your business, <b>putting your needs first and remaining true to the unspoken ethics of the language translation agencies in India.</b> 
                                </p>

                                <p className="story-subtitle mt-3">
                                Wistran offers Professional Translation, Editing, Proofreading, and DTP services for all European, Asian and Indian Languages for the medical, financial, e-learning, legal, media and advertisement, engineering and technical domains.
                                </p>

                                <p className="story-subtitle mt-3">
                                We stay focused on the clients’ needs, combined with superb technical excellence and the commitment of our people. We constantly strive to bring new ideas to work we do to add value and give you the worth of your money.
                                </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />

            </Ax>

        )
    }
}

export default AboutUs;