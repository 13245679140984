import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faIndustry, faLanguage } from "@fortawesome/free-solid-svg-icons";
import '../Home/home.css';
import '../../responsive.css';
import Ax from '../../hoc/Ax';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/navigation/Header';
import { NavLink } from 'react-router-dom';


class Home extends Component {
    render() {
        return (
            <Ax>
                <Header/>
                <section className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">
                        <div className="header-landing text-center">
                        <h2>Who we are?</h2>
                        </div>
                        <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 mx-auto">
                            <p className="landing-main-subtitle mt-3">
                            Nearly a Decade Old Company Headquartered in Chennai
                            </p>

                            <p className="landing-main-subtitle mt-3">
                            <b>Wistran</b> is a young and vibrant company with limitless possibilities. Wistran comprehensive project management capabilities and rigorous quality makes the company standout. 
                            </p>
                        </div>
                                      
                    </div>
                       
                                <div className="row mt-3">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                                    <div className="card">
                                    <div className="card-head">
                                        <FontAwesomeIcon icon={faLanguage} className="landing-icons" />
                                    </div>
                                        <div className="card-body">
                                            <h5 className="card-title card-landing-head">TRANSLATION & LOCALIZATION</h5>
                                            <div className="home-card-text">
                                                <ul>
                                                    <li>We understand your Need and Expectation</li>
                                                    <li>We know, it’s hard to get the qualified linguist</li>
                                                </ul>
                                            </div>
                                            <div className="learn-home">
                                                <NavLink to="/WhatWeDo" exact >Learn more &#10230;</NavLink>
                                            </div>
                                        </div>                                       
                                    </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                                    <div className="card">
                                    <div className="card-head">
                                    <FontAwesomeIcon icon={faIndustry} className="landing-icons" />
                                    </div>
                                        <div className="card-body">
                                            <h5 className="card-title card-landing-head">Industry - (End - Market)</h5>
                                            <div className="home-card-text">
                                                <ul>
                                                    <li>We understand the industry standards</li>
                                                    <li>We understand the Technology know-how</li>
                                                </ul>
                                            </div>
                                            <div className="learn-home">
                                                <NavLink to="/Industry" exact >Learn more &#10230;</NavLink>
                                            </div>
                                        </div>                                       
                                    </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                                    <div className="card">
                                    <div className="card-head ">
                                    <FontAwesomeIcon icon={faBusinessTime} className="landing-icons" />
                                    </div>
                                        <div className="card-body">
                                            <h5 className="card-title card-landing-head">QUALITY ASSURANCE and timely delivery</h5>
                                            <div className="home-card-text">
                                                <ul>
                                                    <li>We understand the timeline</li>
                                                    <li>We understand the pricing</li>
                                                </ul>
                                            </div>
                                            <div className="learn-home">
                                                <NavLink to="/WhyUs" exact >Learn more &#10230;</NavLink>
                                            </div>
                                        </div>                                       
                                    </div>
                                    </div>
                                    
                                    
                                    
                                </div>                              

                                
                            </div>
                            
                        </div>
                    </div>
                </section>
                <Footer />
            </Ax>

        )
    }
}

export default Home;