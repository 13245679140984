import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import {faBars } from "@fortawesome/free-solid-svg-icons";
import Ax from '../../hoc/Ax';
import { NavLink } from 'react-router-dom';
import '../navigation/Navigation.css';
import logo from '../../asserts/images/logo_blue.png';

const NavigationItems = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [expandedMenubar, setExpandedMenubar ] = useState(false);

    function navMenuexpanding() {
        setExpanded(!expanded)
    }
    return (
        <Ax>
            <nav className="navbar fixed-top bg-nav-color justify-content-between navbar-expand-lg">
                <div className="logo-contain">
                    <span className="navbar-brand logo" >
                        <img src={logo} alt="logo" />
                    </span>
                </div>
               
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon icon={faBars} className="menuIcon ml-4" />
                </button>

                <div className={`collapse navbar-collapse ${expanded ? 'menuHide' : null }`} id="navbarSupportedContent" >
                    <ul className="navbar-nav mr-auto " onClick={() => navMenuexpanding()}>
                        {/* <li className="nav-item">
                    <span className="navbar-brand logo" >
                        <img src={logo} alt="logo" />
                    </span>
                        </li> */}
                        <li className="nav-item">
                            <NavLink to="/" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">HOME</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/About-us" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">OUR STORY</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/WhatWeDo" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">WHAT WE DO?</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/WhyUs" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">WHY US?</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/Industry" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">INDUSTRY</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/Language" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">LANGUAGE EXPERTISE</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/Reach" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">REACH US</NavLink>
                        </li>
                    </ul>
                </div>

                <div className="nav-icon">
                    <a target="_blank" href="#">
                        <FontAwesomeIcon icon={faLinkedinIn} className="socialIcon ml-4" />
                     </a> 
                  
                </div>
            </nav>
        </Ax>
    )
}

export default NavigationItems;