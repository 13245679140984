import React, { Component } from 'react';
import Ax from '../../hoc/Ax';
import Footer from '../../components/Layout/Footer';
import '../WhatWeDo/WhatWeDo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faGraduationCap, faBullhorn, faNewspaper, faRecycle, faFileWord, faSitemap } from "@fortawesome/free-solid-svg-icons";

class Minstries extends Component {


    render() {
        return (
            <Ax>

                <section className="m-custom">
                    <div className="header-we-do text-center">
                        <h2>OUR SERVICES</h2>
                    </div>
                    <div className="container-fluid mt-50">
                        <div className="row">
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faLanguage} className="we-do-icons" />
                                        </div>
                                        <div className="we-do-title text-center">TRANSLATION & LOCALIZATION</div>

                                        <p className="we-do-subtitle mt-3">
                                            Our professional translation and localization services support more than 80+ language combinations. our network of remote staffing linguists, reviewers ensure that we have the capacity to handle Huge volume and speed. also, we can help you with <b>Verification/Proofreading</b> and other related services.
                                       </p>
                                        <ul>
                                            <li>Translates any kind of documentation - technical documentation, medical research documentation, medical equipment documentation, software documentation, legal instruments, official papers, advertising publications etc.</li>
                                            <li>Provides the complete localization cycle for different products, all the way from project preparation to delivery to the market.</li>
                                            <li>Translates scientific publications in various regions of science, annotations and articles for publication in a foreign media.</li>
                                            <li>Translates personal documents – all kinds of certificates, agreements, correspondence etc. </li>
                                            <li>Provides Project Management and Linguistic Solutions consulting to your company.</li>
                                        </ul>

                                        <p className="we-do-subtitle mt-3">
                                            To receive a quote for your project, please contact our Project Management Team. We will provide you with the solution you need.
                                       </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faGraduationCap} className="we-do-icons" />
                                        </div>
                                        <div className="we-do-title text-center ">MULTIMEDIA & E-LEARNING services</div>

                                        <p className="we-do-subtitle mt-3">
                                            These days, you need more than a script to build your audience people expects the story in video and audio formats, we can help you with multimedia contents also we provide services on
                                       </p>
                                        <ul>
                                            <li>Transcription</li>
                                            <li>Creative design </li>
                                            <li>Video Subtitling, & Annotation, Image Data Transcription</li>
                                        </ul>

                                        <p className="we-do-subtitle mt-3">
                                            good the translation quality may be, even a small layout or typographical mistake may dramatically impact how users perceive the product or the brand. This is even more so on high-profile materials such as marketing communications, advertising or packaging.
                                       </p>
                                        <ul>
                                            <li>We can help you to typeset Books, Journals, and Translated Documents in Windows in any software's as per your requirement.</li>
                                            <li>We convert all this typeset books, documents and journals into an e-book, e-document and e-journal.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row mt-50">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faBullhorn} className="we-do-icons" />
                                        </div>
                                        <div className="we-do-title text-center mt-3">Marketing & advertisement localization</div>

                                        <p className="we-do-subtitle mt-3">
                                            Advertising and marketing communications is the essential to every business, when we do that in global scale it’s important that we convey the message in the cultural context.
                                       </p>

                                        <p className="we-do-subtitle mt-3">
                                            If businesses intend to improve sales, translating and localizing content into other languages would make a great start. After all, 90% of consumers would always choose their mother tongue whenever the option is available – <a href="https://insights.csa-research.com/reportaction/8057/Marketing" target="_blank" rel="noopener noreferrer">CSA Research</a>.
                                       </p>

                                        <p className="we-do-subtitle mt-3">
                                            We also have history of serving client is Marketing & advertisement segment also we have expertise in creative design and auxiliary services.
                                       </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faNewspaper} className="we-do-icons" />
                                        </div>
                                        <div className="we-do-title text-center mt-3">Multi-lingual Desktop Publishing /Formatting </div>

                                        <p className="we-do-subtitle mt-3">
                                            Desktop publishing is often one of the last phases in a typical translation project, and one that gives the final look and feel to the localized materials. No matter how
                                       </p>

                                        <p className="we-do-subtitle mt-3">
                                            <b>Our desktop publishing services include:</b>
                                        </p>

                                        <ul>
                                            <li>File preparation: We make sure your files are prepared to be translated and printed.</li>
                                            <li>File conversion: If needed, we convert your files to different formats.</li>
                                            <li>Post-translation brush-up: We make sure your translated content looks just as nice as the original.</li>
                                            <li>Publication: We publish your content to a variety of formats like PDF or HTML.</li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="row mt-50">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faFileWord} className="we-do-icons" />
                                        </div>
                                        <div className="we-do-title text-center mt-3">Regulatory Document Specialist.</div>

                                        <p className="we-do-subtitle mt-3">
                                            Translating and localizing the technical documents can be tricky. Especially when we serve Pharma and Legal Services industry. We carry extensive experience on those sectors and we can help.
                                       </p>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faSitemap} className="we-do-icons" />
                                        </div>
                                        <div className="we-do-title text-center mt-3">Web Designing and infographics</div>

                                        <p className="we-do-subtitle mt-3">
                                            We have expertise on web designing also with Infographic Design Services where we can simplify the complicated data's and capture the viewers' attention.
                                       </p>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="text-center">
                                            <FontAwesomeIcon icon={faRecycle} className="we-do-icons" />
                                        </div>
                                        <div className="we-do-title text-center mt-3">Content Transformation Services</div>

                                        <p className="we-do-subtitle mt-3">
                                            As your business expands globally, it’s not enough to simply translate your content from one language to another. You need to transform it so that it speaks to your customers across different markets, cultures, and contexts
                                       </p>
                                        <p className="we-do-subtitle mt-3">
                                            transforms content across platforms, devices, and channels so that it resonates locally and delivers a superior customer experience worldwide. We ensure that your content is relevant and culturally appropriate at every step.
                                       </p>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <Footer />

            </Ax>

        )
    }
}

export default Minstries;