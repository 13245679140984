import React, { Component } from 'react';
import '../Industry/Industry.css';
import '../../responsive.css';
import Footer from '../../components/Layout/Footer';
import Ax from '../../hoc/Ax';

class Industry extends Component {
    render() {
        return (
            <Ax>

                <section>
                    <div className="container-fluid">
                    <div className="row mt-100">
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">
                        <div className="">
                                    <div className="d-inline-block">
                                        <div className="industry-title text-center">Technical translation solutions across a range of industries.</div>
                                        <div className="industry-sub-title text-left mt-3">Medical and Pharma / Life Sciences </div>
                                       
                                <p className="industry-copy mt-3">
                                We are realizing an importance of pharmaceutical companies more than ever with the Covid –19 scenario. In a globalized market working together is the key. With globalization come emerging markets. Pharmaceuticals are seeing an increase in revenue from countries such as Brazil, Russia, India, China, Mexico, Turkey and countries in South East Asia (ref: strategyand.pwc.com). Among the key challenges facing pharmaceutical companies in emerging markets is the ability to successfully localize their market-specific efforts. They must also be sure to meet the regulatory standards in each country as they relate to medicinal product translation. In order for pharmaceuticals to ensure success in these countries, it is imperative that their approach carefully considers localization and translation strategies to maximize performance. 
                                </p>

                                <p className="industry-copy mt-3">
                                When pharmaceutical producers market overseas, companies are necessarily forced to communicate and make their products available in local languages in every region. This need is driven by existing directives and regulatory compliance requirements, which prescribe what content – and when and how – needs to be available to local users. Needless to say, that consumers are even more cautious when dealing with pharmaceutical products. They need to have all the necessary information available in their language to guide them in their choice.
                                </p>

                                <p className="industry-copy mt-3">
                                We are equipped to support your global programs, regulatory submissions, and product marketing efforts. 
                                </p>
                                <p className="industry-copy mt-3">
                                We ensure the translation efforts are Accurate with clarity and consistency
                                </p>


                                <div className="industry-sub-title text-left mt-3">E- Commerce</div>
                                       
                                <p className="industry-copy mt-3">
                                Future of the shopping expected to be multilingual.
                                </p>

                                <p className="industry-copy mt-3">
                                We cannot deny the success of e-commerce in the last decade. UK mobile commerce (m-commerce) projection shows 56 percent of sales will be purchased through a smartphone in 2021. Internet has connected more people than ever before so do the e-commerce; we can now purchase things from other end of the world. Language, once a barrier, is getting broken by e-commerce localization.
                                e-commerce localization helps to strengthen the global presence, enter the untapped markets, boost the customer engagement, trust and satisfaction and ultimately, we can expect the results in revenue increase.

                                </p>

                                <p className="industry-copy mt-3">
                                The key is to stay sensitive to culture and context, Wistran help you to do exactly that.
                                </p>


                                <div className="industry-sub-title text-left mt-3">Education and E-learning </div>
                                       
                                <p className="industry-copy mt-3">
                                In the new normal eLearning has become an effective choice to provide cost-effective, on-demand education, training and support to audiences around the globe targeting the needs across sectors. Mainly the Academic institutions, businesses, governments, and other organizations which heavily rely on tailored multilingual E-Learning modules.
                                </p>

                                <p className="industry-copy mt-3">
                                According to Statista, the global eLearning industry is expected to exceed 243 billion US dollars in market value by 2022.

                                </p>

                                <p className="industry-copy mt-3">
                                Combination of education and technology (Ed-tech) is creating a revolution in the education industry. for example, MOOCs (Massive Open Online Courses) platforms, now in the new era it’s not about traditional course materials, such as filmed lectures, readings, and problem sets. Now MOOCs provide interactive courses with user forums or social media discussions to support community interactions among students, professors, and teaching assistants (TAs), as well as immediate feedback to quick quizzes and assignments. We are yet to unleash the scope of ed-tech with top educational technology trends such as Cloud computing, virtual and augmented learning experience and 3D printing which are available today in some form and advancing exponentially every year.
                                </p>

                                <p className="industry-copy mt-3">
                                Creating an effective multilingual eLearning experiences is not an exception. 
                                </p>

                                <p className="industry-copy mt-3">
                                Wistran can help you with eLearning products in all formats - The Written Content, User Interface and Online Courses etc. we understand E-Learning localization is not just about translation. We do pay attention to customs, graphics, colours, fonts, number formats, and complex multimedia and interactive elements to ensure that the material is not simply understandable but usable, culturally appropriate, meaningful and most importantly – more engaging.
                                </p>

                                <p className="industry-copy mt-3">
                                We can also help you with localization of Prints Publications – Books, magazines, pamphlet, letter, poster, brochure etc. 
                                </p>

                                <div className="industry-sub-title text-left mt-3">Advertisement and Marketing</div>
                                       
                                       <p className="industry-copy mt-3">
                                       A <a href="https://blog.languageline.com/what-is-localization" target="_blank" rel="noopener noreferrer">study found</a> that 75% of buyers are more likely to purchase a product in a language that they understand.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       Communicating the brand and message globally is not just about the product. We need to make sure to research and understand the language (dialects and local vocabulary), culture, cultural references, and audience of the market that we want to target to avoid the <a href="https://www.inc.com/geoffrey-james/the-20-worst-brand-translations-of-all-time.html" target="_blank" rel="noopener noreferrer">epic fails in global branding</a>. - marketing campaigns to foreign markets can be a risky business without the proper professional marketing translation service. Accurate marketing campaign translation and localization is essential.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       <b>If You're Going Global, Localization Is Key</b> - as It also helps your organic visibility by improving your rankings and SEO locally, Decreasing the barrier to market-entry, Brand identification amongst local market and lot more advantages. 
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       To achieve that we need - As Digital Marketing Director rightly puts it <b>“Our content has to be local and global at the same time.” </b> 
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       Wistran offer you a professional marketing translation service and We can help you with localization of Websites & landing pages, Branding materials, Advertising – print & digital, Social Media & user generated content, SEO & search campaigns, Email marketing, E-Commerce, Customer support, Product & sales brochures, PR, corporate & partner communications and Multimedia etc.
                                       </p>


                                       <div className="industry-sub-title text-left mt-3">IT & Technology</div>
                                       
                                       <p className="industry-copy mt-3">
                                       Digital technologies have risen to prominence as a critical determinant of economic growth, national security, and international competitiveness. The digital economy has a profound influence on the world’s trajectory and the societal well-being of ordinary citizens. It affects everything from resource allocation to income distribution and growth.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       In global landscape of technology, it's important that technology company must stay competitive. One factor which mark the company is its vibrant innovation ecosystem. It also become unbeneficial if the innovation is not moved from one culture and economy into another.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       By combining its wealth of translation experience with the latest technologies and customer-focused project management, Wistran has helped many companies localized their products and services.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       Wistran provides a number of services for IT& Technology industry which include localization of Website & App , Maintenance and Operating Manual, UX & UI, Product Specification Sheet, Packaging & Regulatory Information, Patents & Legal Documents, Digital Promotions, Product Registrations, Technical Documents, Troubleshooting Guides, Patents, Company files, Audiovisual marketing material, Commercial literature, Protocols and instructions, Publications and review, Software, Technical documents Etc...
                                       </p>


                                       <div className="industry-sub-title text-left mt-3">Engineering & Manufacturing</div>
                                       
                                       <p className="industry-copy mt-3">
                                       To create a World class product, we often need a global supply chain support. with the rise of digital logistics and advanced technological products, the manufacturing and engineering industry has become the chief sector for development across the world. The demand for companies’ expansion in this field leads to an increasing trend towards investing worldwide to find potential markets.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       The process of converting raw materials into finished products now often involves multiple countries, location and languages. Raw materials originate in one set of countries, parts can be sourced in others and assembly may occur in still more countries. <b>The difficulty is Not only to reach customers overseas but also to understand all the instructions during the process of production.</b> Thus, translation and localization are essential to be understood and to understand the people you interact with when doing business.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       Engineering & Manufacturing are facing a tough competition from emerging countries like China and India where the labour cost is very low. So, one of the solutions for this sector to survive is to export or relocate in order to become profitable. To make it possible, translation and localisation services are the key for your business success abroad.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       Wistran work with all aspects of industrial Engineering & Manufacturing documentation including: User Manuals, Training & Safety Documentation, Contracts & Agreements; Operating Instructions; Regulatory Documentation; Patents & Intellectual Property (IP) Materials; Electrical and electronic systems and products, Manufacturing plants and processes, Chemical products, chemical safety, and Material Safety Data Sheets (MSDS), Engineering schematics, Energy, Oil, Gas & Petrochemical Content, industrial Mining; Clean Energy systems etc.
                                       </p>
       
                                       <div className="industry-sub-title text-left mt-3">Media and Entertainment</div>
                                       
                                       <p className="industry-copy mt-3">
                                       The media & entertainment landscape is changing rapidly, content providers are turning to digital platforms that are dominating social media to promote their short and long-form media & entertainment products. Whether you are talking about a radio, digital, Film, print and television or other content video formats, digital assets are becoming the proven leader for ROI in marketing spend.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       Media localization used to be the non-attractive part of the media industry due to its challenge on huge volumes, tight deadlines and cost. Demand for high quality, well-paid localization work was higher than ever. Wistran address the challenge with Professional and customer-focused project management capabilities. 
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       We work with all type of multimedia content such as Film (documentaries & interviews), Advertising, Brochures, Newsletters, Social Media Postings, Magazines, Sales Presentations, Digital and Print Media, Websites, Streaming, Live Broadcast, Software & Apps, Podcast, Gaming etc
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       We can help you with subtitling, dubbing scripts, lyrics, voiceover & dubbing, Graphics Localization, DTP, captions, interpretation, Metadata Translation, document translation, Transcreation and multimedia localization.
                                       </p>


                                       <div className="industry-sub-title text-left mt-3">Banking, Finance and Insurance</div>
                                       
                                       <p className="industry-copy mt-3">
                                       The financial world is in evolution. Cross-border business transactions, investments, international M&A, and the shift towards decentralized banking are making finance a truly global affair.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       According to PwC, <a href="https://www.pwc.com/gx/en/industries/financial-services/publications/emerging-markets-driving-payments.html" target="_blank" rel="noopener noreferrer">85% of the world’s population lives</a> in emerging markets. And that number rises to 90% once you focus in on people under 30. Yet, rather than classing these new markets as “low-hanging fruit,” banks will need to put in extra time and effort to win over a culturally diverse customer base. investing in expert translation services to reach these emerging markets and to gain the trust of new customers is crucial.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       It's not just about the customers when potential investors are deciding where to allocate their funds, they often rely on annual reports, company press releases, and presentations. And it’s crucial that we provide the information in the accurate and accessible manner- in their first language.
                                       </p>
       
                                       <p className="industry-copy mt-3">
                                       In order to globalize these crucial services, you need to speak to all the stakeholders in their own language and earn their trust. Wistran Translation services make this simple ensuring that your content remains clear, concise, and consistent when it also meets Confidential policies and Quality. 
                                       </p>

                                       <p className="industry-copy mt-3">
                                       We can help you with localization of content and document such as Bank or Financial Institutions Website (Website Localization), Financial Statements, Contracts, Marketing Brochures, Loan Information, Credit Information, Banking & Account Statements, Fiscal Revenue Reports, Mortgage Terms, Conditions & Contacts, Annual Reports, Application Forms, Audit Reports, Investment Reports, Disclosure Agreements, Shareholder Information, Compliance Documents, M&A pitches and presentations, IPO documentation, Research reports etc.
                                       </p>
       
                                       <div className="industry-sub-title text-left mt-3">Other industries</div>
                                       
                                       <p className="industry-copy mt-3">
                                       All industries are different and so are their needs. Our services are not limited to the listed industries we also support industries such as Govt and public services, legal, travel and hospitality, Energy & Mining, Politics etc. Don’t hesitate to contact us today to talk about your new projects and future aspirations. 
                                       </p>    
                                      
                                    </div>
                                </div>

                        </div>

                    </div>    
                    </div>
                </section>

                <Footer />
            </Ax>

        )
    }
}

export default Industry;