import React, { Component } from 'react';
import '../Language/Language.css';
import '../../responsive.css';
import Footer from '../../components/Layout/Footer';
import Ax from '../../hoc/Ax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle,faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import Indian from '../../asserts/images/indian.png';
import African from '../../asserts/images/african.png';
import European from '../../asserts/images/european.png';
import Asian from '../../asserts/images/asian.png';
import Eastern from '../../asserts/images/eastern.png';


class Language extends Component {
  state = { isActiveIndian: false, isActiveAsian: false, isActiveEastern: false, isActiveEuropean: false, isActiveAfrican: false  };

  indianToggle = () => {
    this.setState({ isActiveIndian: !this.state.isActiveIndian});
  };
  asianToggle = () => {
    this.setState({  isActiveAsian: !this.state.isActiveAsian });
  };

  easternToggle = () => {
    this.setState({ isActiveEastern: !this.state.isActiveEastern});
  };
  europeanToggle = () => {
    this.setState({  isActiveEuropean: !this.state.isActiveEuropean });
  };

  africanToggle = () => {
    this.setState({ isActiveAfrican: !this.state.isActiveAfrican});
  };

    render() {
      const isActiveIndian = this.state.isActiveIndian;
      const isActiveAsian = this.state.isActiveAsian;
      const isActiveEastern = this.state.isActiveEastern;
      const isActiveEuropean = this.state.isActiveEuropean;
      const isActiveAfrican = this.state.isActiveAfrican;
        return (
            <Ax>
                <section className="spx-4 language-main">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">

                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                          <div className="language-card">
                          <div className="language-header">
                            <div className="d-inline-block langauge-flag"><img src={Indian} alt="Indian Flag"/></div>
                            <div className="d-inline-block language-country">Indian Languages</div>
                            <div className={`d-inline-block language-toggle-icon ${isActiveIndian ? "hide" : null}`}><FontAwesomeIcon icon={faPlusCircle} onClick={this.indianToggle} /></div>
                            <div className={`d-inline-block language-toggle-icon ${!isActiveIndian ? "hide" : null}`}><FontAwesomeIcon icon={faMinusCircle} onClick={this.indianToggle} /></div>
                          </div>
                          <div className={`language-body ${!isActiveIndian ? "hide" : null}`}>
                        <ul>
                          <li>Assamese</li>
                          <li>Bengali</li>
                          <li>Gujarati</li>
                          <li>Hindi</li>
                          <li>Kannada</li>
                          <li>Kashmiri</li>
                          <li>Malayalam</li>
                          <li>Marathi</li>
                          <li>Manipuri</li>
                          <li>Oriya</li>
                          <li>Punjabi</li>
                          <li>Sindhi</li>
                          <li>Sanskrit</li>
                          <li>Tamil</li>
                          <li>Telugu</li>
                          <li>Urdu</li>
                          <li>Bodo</li>
                          <li>Maithili</li>
                          <li>Sentali</li>
                          <li>Nepali</li>
                          <li>Dogri</li>
                        </ul>
                          </div>
                        </div>
                          </div>


                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                          <div className="language-card">
                          <div className="language-header">
                            <div className="d-inline-block langauge-flag"><img src={Asian} alt="Asian Flags"/></div>
                            <div className="d-inline-block language-country">Asian Languages</div>
                            <div className={`d-inline-block language-toggle-icon ${isActiveAsian ? "hide" : null}`}><FontAwesomeIcon icon={faPlusCircle} onClick={this.asianToggle} /></div>
                            <div className={`d-inline-block language-toggle-icon ${!isActiveAsian ? "hide" : null}`}><FontAwesomeIcon icon={faMinusCircle} onClick={this.asianToggle} /></div>
                          </div>
                          <div className={`language-body ${!isActiveAsian ? "hide" : null}`}>
                        <ul>
                          <li>Burmese </li>
                          <li>Bangladeshi </li>
                          <li>Chinese </li>
                          <li>Cambodian </li>
                          <li>Dari </li>
                          <li>Indonesian </li>
                          <li>Japanese </li>
                          <li>Khmer </li>
                          <li>Korean </li>
                          <li>Kurdish </li>
                          <li>Lao </li>
                          <li>Malay </li>
                          <li>Malagasy </li>
                          <li>Nepalese </li>
                          <li>Pashto </li>
                          <li>Singhalese </li>
                          <li>Sorani </li>
                          <li>Sylheti </li>
                          <li>Thai </li>
                          <li>Vietnamese</li>
                        </ul>
                          </div>
                        </div>
                          </div>


                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                          <div className="language-card">
                          <div className="language-header">
                            <div className="d-inline-block langauge-flag"><img src={Eastern} alt="Eastern Flags"/></div>
                            <div className="d-inline-block language-country">Middle East & Eastern European Languages</div>
                            <div className={`d-inline-block language-toggle-icon ${isActiveEastern ? "hide" : null}`}><FontAwesomeIcon icon={faPlusCircle} onClick={this.easternToggle} /></div>
                            <div className={`d-inline-block language-toggle-icon ${!isActiveEastern ? "hide" : null}`}><FontAwesomeIcon icon={faMinusCircle} onClick={this.easternToggle} /></div>
                          </div>
                          <div className={`language-body ${!isActiveEastern ? "hide" : null}`}>
                        <ul>
                          <li>Albanian</li>
                          <li>Arabic </li>
                          <li>Bulgarian </li>
                          <li>Croatian </li>
                          <li>Czech </li>
                          <li>Estonian </li>
                          <li>Farsi </li>
                          <li>Hebrew </li>
                          <li>Hungarian </li>
                          <li>Mongolian </li>
                          <li>Polish </li>
                          <li>Romanian </li>
                          <li>Russian </li>
                          <li>Serbian </li>
                          <li>Slovenian </li>
                          <li>Slovak (Solvency) </li>
                          <li>Tajik </li>
                          <li>Turkish </li>
                          <li>Ukranian </li>
                          <li>Uzbek</li>
                        </ul>
                          </div>
                        </div>
                          </div>

                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                          <div className="language-card">
                          <div className="language-header">
                            <div className="d-inline-block langauge-flag"><img src={European} alt="European Flags"/></div>
                            <div className="d-inline-block language-country">European Languages</div>
                            <div className={`d-inline-block language-toggle-icon ${isActiveEuropean ? "hide" : null}`}><FontAwesomeIcon icon={faPlusCircle} onClick={this.europeanToggle} /></div>
                            <div className={`d-inline-block language-toggle-icon ${!isActiveEuropean ? "hide" : null}`}><FontAwesomeIcon icon={faMinusCircle} onClick={this.europeanToggle} /></div>
                          </div>
                          <div className={`language-body ${!isActiveEuropean ? "hide" : null}`}>
                        <ul>
                          <li>Danish</li>
                          <li>Dutch </li>
                          <li>Finnish </li>
                          <li>French </li>
                          <li>Flemish </li>
                          <li>German </li>
                          <li>Greek </li>
                          <li>Icelandic </li>
                          <li>Italian </li>
                          <li>Maltese </li>
                          <li>Norwegian </li>
                          <li>Portuguese </li>
                          <li>Spanish </li>
                          <li>Swedish</li>
                        </ul>
                          </div>
                        </div>
                          </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                          <div className="language-card">
                          <div className="language-header">
                            <div className="d-inline-block langauge-flag"><img src={African} alt="African Flags"/></div>
                            <div className="d-inline-block language-country">African Languages</div>
                            <div className={`d-inline-block language-toggle-icon ${isActiveAfrican ? "hide" : null}`}><FontAwesomeIcon icon={faPlusCircle} onClick={this.africanToggle} /></div>
                            <div className={`d-inline-block language-toggle-icon ${!isActiveAfrican ? "hide" : null}`}><FontAwesomeIcon icon={faMinusCircle} onClick={this.africanToggle} /></div>
                          </div>
                          <div className={`language-body ${!isActiveAfrican ? "hide" : null}`}>
                        <ul>
                          <li>Afrikaans </li>
                          <li>Amharic</li>
                          <li>Swahili</li>
                          <li>Somali</li>
                          <li>Twi</li>
                          <li>Zulu</li>
                          <li>Lingala</li>
                        </ul>
                          </div>
                        </div>
                          </div>    

                        </div>

                        </div>                    
                                      
                    </div>
                        
                    </div>
                </section>

                <Footer />
            </Ax>

        )
    }
}

export default Language;