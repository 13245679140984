import React, { Component } from 'react';
import Ax from '../../hoc/Ax';
import Footer from '../../components/Layout/Footer'
import '../Reach/Reach.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
class Reach extends Component {

    state = { isActiveEnquiry: false, isActivePartner: true};

  partnerFormToggle = () => {
    this.setState({ isActiveEnquiry: false});
    this.setState({  isActivePartner: true });
    
  };
  enquiryFormToggle = () => {
    this.setState({ isActiveEnquiry: true});
    this.setState({  isActivePartner: false });
    
  };
    
    render() {
        const isActiveEnquiry = this.state.isActiveEnquiry;
        const isActivePartner = this.state.isActivePartner;
        return (
            <Ax>

                <section className="m-custom">
                    <header className="header-bg">
                        
                    </header>
                </section>

                <section className="px-3">
                    <div className="container-fluid">
                    <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mx-auto">
                        <div className="contact-card">
                        <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className={` nav-link  ${isActiveEnquiry ? "active" : null}`} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={this.partnerFormToggle}>To Become Partner With Us</button>
                            <button className={` nav-link  ${isActivePartner ? "active" : null}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={this.enquiryFormToggle}>Customer Enquiry Form</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                        <div className={` ${isActiveEnquiry ? "hide" : null}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className="row">
                            <div className="col-12">
                                <div className="text-center">
                                    <div className="d-inline-block">                                        
                                        <p className="contact-subtitle mt-3">
                                            Partner With Us
                                    </p>
                                    </div>
                                </div>                                
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                <form>
                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputFirstName" className=" col-form-label">First Name</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <input type="text" className="form-control" name="inputFisrtName" id="inputFirstName" placeholder="John"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputLastName" className=" col-form-label">Last Name</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <input type="text" className="form-control" name="inputLastName" id="inputLastName" placeholder="Joe" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputEmail" className="col-form-label">Email</label>
                                            </div>
                                           
                                            <div className="col-12">
                                                <input type="email" className="form-control" id="inputEmail" placeholder="demo@example.com" />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputMobile" className=" col-form-label">Mobile</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <input type="text" className="form-control" id="inputMobile" placeholder="Enter your mobile number"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="companyName" className=" col-form-label">Company Name</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <input type="text" className="form-control" name="companyName" id="companyName" placeholder="Your company name" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputRequest" className=" col-form-label">What can we do for you?</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <textarea className="form-control" rows="5" cols="10" id="inputRequest" name="inputRequest" placeholder="Please give us a brief description of your request."/> 
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group row my-5">
                                        <div className="col-sm-12 btn-align">
                                            <button type="submit" className="btn btn-primary">SEND</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div> </div>
                        <div className={` ${isActivePartner ? "hide" : null}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center">
                                    <div className="d-inline-block">                                        
                                        <p className="contact-subtitle mt-3">
                                            Enquiry Us
                                    </p>
                                    </div>
                                </div>                                
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                <form>
                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputFirstName" className=" col-form-label">First Name</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <input type="text" className="form-control" name="inputFisrtName" id="inputFirstName" placeholder="John"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputLastName" className=" col-form-label">Last Name</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <input type="text" className="form-control" name="inputLastName" id="inputLastName" placeholder="Joe" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputEmail" className="col-form-label">Email</label>
                                            </div>
                                           
                                            <div className="col-12">
                                                <input type="email" className="form-control" id="inputEmail" placeholder="demo@example.com" />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputMobile" className=" col-form-label">Mobile</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <input type="text" className="form-control" id="inputMobile" placeholder="Enter your mobile number"/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="contact-form">
                                        <div className="form-group row">
                                        <div className="col-12">
                                        <label htmlFor="inputRequest" className=" col-form-label">What would you like to know of us?</label>
                                            </div>
                                            
                                            <div className="col-12">
                                                <textarea className="form-control" rows="5" cols="10" id="inputRequest" name="inputRequest" placeholder="Please give us a brief description of your request."/> 
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group row my-5">
                                        <div className="col-sm-12 btn-align">
                                            <button type="submit" className="btn btn-primary">SEND</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                             </div>
                        </div>
                            <div className="contact-header text-center">
                                Get In Touch
                            </div>
                            <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">
                            <div className="contact-body">
                                <div className="contact-section">
                                <div className="d-inline-block contact-icons"><FontAwesomeIcon icon={faPhone} /></div>
                                <div className="d-inline-block contact-details"><a href="tel:+91 9840528905" target="_blank" rel="noopener noreferrer">+91 9840528905</a></div>
                                </div>
                               
                                <div className="contact-section">
                                <div className="d-inline-block contact-icons"><FontAwesomeIcon icon={faEnvelope} /></div>
                                <div className="d-inline-block contact-details"><a href="mailto:info@wistrantech.com" target="_blank" rel="noopener noreferrer">info@wistrantech.com</a></div>
                                </div>
                                <div className="contact-section">
                                <div className="d-inline-block contact-icons"><FontAwesomeIcon icon={faLocationArrow} /></div>
                                <div className="d-inline-block contact-details"> Sembakkam, Chennai, Tamilnadu, India</div>
                                </div>
                                <div>
                               
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>


                       
                    </div>
                    </div>    

                    

                        

                    </div>
                </section>
                <Footer />
            </Ax>

        )
    }
}

export default Reach;