import React from 'react';
import '../Layout/Layout.css'
import { NavLink } from 'react-router-dom';
const Footer = () => {

    return (

        <footer className="footer-contain">
            <section>
                <div className="container-fluid margin-space">
                    <div className="row mt-4">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="col-12 text-left">
                                <div className="company-header">BLOG</div>
                                <div className="footer-item">
                                <a href="https://cdn2.hubspot.net/hubfs/2734675/Reports,%20ebooks/The%20Translation%20Industry%20in%202022.pdf" target="_blank" rel="noopener noreferrer">Future of Translation Industry </a>
                        </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">

                            <div className="col-12 text-left">
                                <div className="company-header">WHO WE ARE?</div>
                                <p className="company-details">Nearly a Decade Old Company Headquartered in Chennai.<br/><b>Wistran</b> is a young and vibrant company with limitless possibilities. Wistran comprehensive project management capabilities and rigorous quality makes the company standout. </p>
                            </div>
                            
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="col-12 text-left">
                                <div className="company-header ">COMPANY</div>
                         <div className="footer-item">
                            <NavLink to="/" exact className="footer-link footer-menu" >HOME</NavLink>
                        </div>
                        <div className="footer-item">
                            <NavLink to="/About-us" exact className="footer-link footer-menu" >OUR STORY</NavLink>
                        </div>
                        <div className="footer-item">
                            <NavLink to="/WhatWeDo" exact className="footer-link footer-menu">WHAT WE DO?</NavLink>
                        </div>
                        <div className="footer-item">
                            <NavLink to="/WhyUs" exact className="footer-link footer-menu">WHY US?</NavLink>
                        </div>
                        <div className="footer-item">
                            <NavLink to="/Industry" exact className="footer-link footer-menu">INDUSTRY</NavLink>
                        </div>
                        <div className="footer-item">
                            <NavLink to="/Language" exact className="footer-link footer-menu">LANGUAGE EXPERTISE</NavLink>
                        </div>
                        <div className="footer-item">
                            <NavLink to="/Reach" exact className="footer-link footer-menu">REACH US</NavLink>
                        </div>     
                            </div>
                            

                        </div>

                        <div className="col-12 text-center mt-5 mb-3">
                            <div className="footer-note ">
                                Copyright &copy;2021 - Wistran Translation and Digital Services. All Right Reserved
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )

}

export default Footer;