import React from 'react';
import './App.css';
import Layout from './components/Layout/Layout';
import Ax from './hoc/Ax';
import ScrollTop from './hoc/ScrollTop';
import { Route, Switch, HashRouter } from 'react-router-dom';
import Home from './containers/Home/home';
import AboutUs from './containers/AboutUs/AboutUs';
import Reach from './containers/Reach/Reach';
import WhyUs from './containers/WhyUs/WhyUs';
import Industry from './containers/Industry/Industry';
import WhatWeDo from './containers/WhatWeDo/WhatWeDo';
import Language from './containers/Language/Language';
function App() {
  return (
    <HashRouter>
      <Ax>
        <Layout />
      </Ax>
      <ScrollTop>
        <Switch>
          <Route path="/" exact strict component={Home} />
          <Route path="/About-us" exact component={AboutUs} />
          <Route path="/WhyUs" exact component={WhyUs} />
          <Route path="/WhatWeDo" exact component={WhatWeDo} />
          <Route path="/Industry" exact component={Industry} />
          <Route path="/Language" exact component={Language} />
          <Route path="/Reach" exact component={Reach} />
          <Route path="**" component={Home} />
        </Switch>
      </ScrollTop>
    </HashRouter>

  );
}

export default App;
