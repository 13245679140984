import React, { Component } from 'react'
import '../WhyUs/WhyUs.css'
import Footer from '../../components/Layout/Footer'
import Ax from '../../hoc/Ax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHandHoldingUsd, faCogs, faBusinessTime, faLanguage, faTasks, faWarehouse } from "@fortawesome/free-solid-svg-icons";

class WhyUs extends Component {
   


    render() {

        return (

            <Ax>
                <section className="m-custom"> 
                <div className="header-why-us text-center">
                       <h2>Why Choose Wistran?</h2>
                    </div>                  
                    <div className="container-fluid">
                    <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 mx-auto">                    
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 mx-auto">
                        <div className="why-us-title text-center">Tuned for Professional Services</div>
                    <p className="why-us-subtitle mt-3">
                    At wistran we help you do more for less. and we don’t shy away from a challenge. We can also help you on related services with ease as one-stop solution.
                                       </p>
                        </div>
                                      
                    </div>

                    


                    <div className="row mt-50">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div  className="text-center">
                        <FontAwesomeIcon icon={faHandHoldingUsd} className="why-us-icons" />
                        </div>
                        <div className="why-us-product-title text-center mt-3">Competitive pricing </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div  className="text-center">
                        <FontAwesomeIcon icon={faCogs} className="why-us-icons" />
                        </div>
                        <div className="why-us-product-title text-center mt-3">Technologies and Tools – TMS and productivity (CAT) tools</div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div  className="text-center">
                        <FontAwesomeIcon icon={faBusinessTime} className="why-us-icons" />
                        </div>
                        <div className="why-us-product-title text-center mt-3">QUALITY ASSURANCE and timely delivery </div>
                        </div>                            
                    </div>

                    <div className="row mt-50">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div  className="text-center">
                        <FontAwesomeIcon icon={faLanguage} className="why-us-icons" />
                        </div>
                        <div className="why-us-product-title text-center mt-3">Professional Human Translation</div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div  className="text-center">
                        <FontAwesomeIcon icon={faTasks} className="why-us-icons" />
                        </div>
                        <div className="why-us-product-title text-center mt-3">Comprehensive Project Management </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div  className="text-center">
                        <FontAwesomeIcon icon={faWarehouse} className="why-us-icons" />
                        </div>
                        <div className="why-us-product-title text-center mt-3">suitable linguistic resources</div>
                        </div>                             
                    </div>

                    </div> 
                    </div> 
                    </div>
                </section>
                <Footer />
            </Ax>

        )
    }
}

export default WhyUs;