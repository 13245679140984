import React, { Component } from 'react';
import './Header.css';
import Ax from '../../hoc/Ax';

class Header extends Component {

  render() {

    return (
      <Ax>
        <section className="m-custom">
          <header className="header-main">        
          </header>
        </section>
      </Ax>
    )
  }

}

export default Header;